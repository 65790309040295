:root {
  $cellBackgroundColor: #0a1a59;
}

.encounter-grid {
  display: grid;
  grid-template-columns: minmax(120px, 1fr) repeat(6, 1fr); // Header column + 7 columns
  grid-template-rows: minmax(50px, 1fr) repeat(9, 1fr); // Header row + 7 rows
  grid-gap: 4px;
}

.empty-cell {
  /* Style for the empty top-left cell */
}

.raider-header {
  font-weight: bold;
  background-color: #333;
  color: white;
  padding: 8px;
}

.encounter-name {
  font-weight: bold;
  background-color: #333;
  color: white;
  padding: 8px;
}

.grid-cell {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.modifier-cell {
  background-color: #0a1a59;
  padding: 8px;
  width: 100%;
  border: 1px solid #cccccc6a;
  flex: 1;
  margin: auto;
}

.selected {
  border: 1px solid #fff;
}
