.tooltip-container {
  position: relative;
}

.tooltip-container .tooltip {
  visibility: hidden;
  width: 150px;
  background-color: rgb(176, 176, 219);
  color: rgb(16, 2, 2);
  font-size: 16px;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  left: 105%;
  transform: translate(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  border: 1px solid white;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
